import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { _einsatzProMonat, _clearData } from "../../store/actions/actions";

import AreaChooserHoc from "../../hoc/AreaChooserHoc";
import StatisticChooser from "../../components/StatisticChooser/StatisticChooser";
import Areas from "../../components/Areas/Areas";
import MonthYear from "../../components/MonthYear/MonthYear";
import Diagramm from "../../components/Diagramm/ProMonat/Diagramm";

const ProMonat = ({ chooseAreas }) => {
	const selectedAreas = useSelector((state) => state.selectedAreas);
	const dispatch = useDispatch();
	const einsatzProMonat = useCallback((areaIds) => dispatch(_einsatzProMonat(areaIds)), [dispatch]);
	const clearData = useCallback(() => dispatch(_clearData()), [dispatch]);

	useEffect(() => {
		einsatzProMonat(selectedAreas);
	}, [einsatzProMonat, selectedAreas]);

	useEffect(() => {
		return () => {
			clearData();
		};
	}, [clearData]);

	return (
		<AreaChooserHoc>
			<div id="panel">
				<StatisticChooser />
				<div className="detailSelector">
					<Areas chooseAreas={chooseAreas} />
					<MonthYear />
				</div>
				<Diagramm />
			</div>
		</AreaChooserHoc>
	);
};

export default ProMonat;
