import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { _getAreas } from "../../store/actions/actions";

const AreaChooser = () => {
	const areas = useSelector((state) => state.areas);
	const dispatch = useDispatch();
	const getAreas = useCallback(() => dispatch(_getAreas()), [dispatch]);

	useEffect(() => {
		if (!areas.length) {
			getAreas();
		}
	}, [areas, getAreas]);

	return (
		<ul className="areaChooser">
			{areas.map((entry, index) => (
				<li key={index}>{entry.Bereich}</li>
			))}
		</ul>
	);
};

export default AreaChooser;
