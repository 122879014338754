import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const StatisticChooser = () => {
	const history = useHistory();
	const location = useLocation();
	const privilegs = useSelector((state) => state.privilegs);

	const goTo = (location) => {
		history.push(location);
	};

	const statistics = [
		{ url: "/proWochentag", name: "Einsätze pro Wochentag" },
		{ url: "/proMonat", name: "Einsätze im Monat" },
		{ url: "/stundenImTag", name: "Einsatzverteilung im Tag" }
	];

	if (privilegs.indexOf("els_statistic_all") > -1) {
		//statistics.push({ url: "/individuelleAuswertung", name: "Individuelle Auswertung" });
		statistics.push({ url: "/auswertungCorona", name: "Auswertung Corona" });
		//statistics.push({ url: "/sarsCoV2Vaccinations", name: "SARS-CoV-2 Impfungen" });
	}

	const statisticsMenu = statistics.map((entry) => (
		<li key={entry.url} className={location.pathname === entry.url ? "selected" : ""} onClick={() => goTo(entry.url)}>
			{entry.name}
		</li>
	));

	return <ul className="statisticChooser">{statisticsMenu}</ul>;
};

export default StatisticChooser;
