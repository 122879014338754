import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import AerztefonLogo from "../../ui/AerztefonLogo";
import ScreenMediaLogo from "../../ui/ScreenMediaLogo";
import Spinner from "../../ui/Spinner";
import Menu from "../../components/Menu/Menu";

import test from "../../assets/images/test.gif";
import development from "../../assets/images/development.gif";

const Layout = ({ children }) => {
	const loading = useSelector((state) => state.loading);
	const version = useSelector((state) => state.version);

	if (document.getElementById("topBar")) {
		if (process.env.REACT_APP_ENV === "test") {
			document.getElementById("topBar").style.backgroundImage = `url(${test})`;
		} else if (process.env.REACT_APP_ENV === "development") {
			document.getElementById("topBar").style.backgroundImage = `url(${development})`;
		}
	}

	return (
		<Fragment>
			<div id="topBar">
				<Menu />
				<AerztefonLogo version={version} />
			</div>
			<main>{children}</main>
			{loading && <Spinner />}
			<footer>
				<ScreenMediaLogo />
			</footer>
		</Fragment>
	);
};

export default Layout;
