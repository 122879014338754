import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_AXIOS_URL
});


 // Add a request interceptor
 instance.interceptors.request.use(
	config => {
		const token =  localStorage.getItem('token');
		console.log('interceptors. Token: ' + token);
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		return config;
	},
	error => {
		Promise.reject(error)
	});

instance.interceptors.response.use((response) => {
		return response
	},
	function (error) {
		console.group('instance.interceptors.response');
		const originalRequest = error.config;
		console.log(originalRequest);
		if (originalRequest.url !== 'login' && error.response.status === 401 && !originalRequest._retry) {
		
			originalRequest._retry = true;
			console.log('Getting a new token with the refresh token');
			return axios.post(process.env.REACT_APP_AXIOS_URL + 'refreshToken',
				{
					"refreshToken": localStorage.getItem('refreshToken')
				})
				.then(res => {
					if (res.status === 201) {
						console.log('Juhheee, new TOKEN');
						console.log(res);
						originalRequest.headers['Authorization'] = 'Bearer ' + res.data.token;
						originalRequest.updatedToken = {token: res.data.token, refreshToken: res.data.refreshToken, expiresIn: res.data.expiresIn};
						console.groupEnd();
						return axios(originalRequest);
					}
				})
		}
		console.groupEnd();
		// return Error object with Promise
		return Promise.reject(error);
	}
);

export default instance;