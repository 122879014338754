import React from "react";

const Tabelle = ({ data }) => {
	const header =
		data && data.dates && data.dates.length
			? data.dates.map((date, index) => (
					<th key={index}>
						<span>{date.date}</span>
					</th>
			  ))
			: [];
	header.unshift(<th key="total">Total</th>);
	header.unshift(<th key="zip">PLZ</th>);
	header.unshift(<th key="doctor">Arzt</th>);

	const summary =
		data && data.dates && data.dates.length
			? data.dates.map((date, index) => (
					<td key={index}>
						{date.total.first}/{date.total.second}
					</td>
			  ))
			: [];
	data &&
		summary.unshift(
			<td key="total">
				{data.total}/{data.totalSecondVaccination}
			</td>
		);
	summary.unshift(
		<td key="zip">
			<br />
		</td>
	);
	summary.unshift(
		<td key="doctor">
			<br />
		</td>
	);

	const content =
		data && data.dates && data.dates.length
			? data.vaccinations.map((entry, index) => {
					const dates = data.dates.map((date, index) => {
						if (entry[date.id]) {
							return (
								<td key={index}>
									{entry[date.id].first}/{entry[date.id].second ? entry[date.id].second : 0}
								</td>
							);
						} else {
							return (
								<td key={index}>
									<br />
								</td>
							);
						}
					});
					return (
						<tr key={index}>
							<td>
								{entry.lastname} {entry.firstname}
							</td>
							<td>{entry.zip}</td>
							<td>
								{entry.countPatients}/{entry.countSecondVaccination ? entry.countSecondVaccination : 0}
							</td>
							{dates}
						</tr>
					);
			  })
			: null;

	return (
		<table className="vaccination">
			<thead>
				<tr>{header}</tr>
			</thead>
			<tbody>
				<tr className="summary">{summary}</tr>
				{content}
			</tbody>
		</table>
	);
};

export default Tabelle;
