import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { _logout } from "../../store/actions/actions";

const Menu = () => {
	const isAuthenticated = useSelector((state) => state.token !== null);
	const username = useSelector((state) => state.realname);
	const privilegs = useSelector((state) => state.privilegs);
	const dispatch = useDispatch();
	const logout = () => dispatch(_logout());

	const onLogout = () => {
		logout();
	};

	if (isAuthenticated) {
		return (
			<div id="menu">
				<div>
					<div className="menu">
						Menü
						<ul>
							{(privilegs.indexOf("mitarbeiter") > -1 || privilegs.indexOf("coronaRecording") > -1 || privilegs.indexOf("taz") > -1 || privilegs.indexOf("schichtleiter") > -1) && (
								<li>
									<a href={process.env.REACT_APP_WEB_ELS} rel="noopener noreferrer">
										Web-ELS
									</a>
								</li>
							)}
							{privilegs.indexOf("ticket") > -1 && (
								<li>
									<a href={process.env.REACT_APP_TICKET} rel="noopener noreferrer">
										Ticket
									</a>
								</li>
							)}
							{(privilegs.indexOf("admin") > -1 || privilegs.indexOf("rosterAdmin") > -1 || privilegs.indexOf("downloadStatistic") > -1) && (
								<li>
									<a href={process.env.REACT_APP_ADMIN} rel="noopener noreferrer">
										Administration
									</a>
								</li>
							)}
							{privilegs.indexOf("admin") && (
								<li>
									<a href={process.env.REACT_APP_USERMANAGEMENT} rel="noopener noreferrer">
										Benutzerverwaltung
									</a>
								</li>
							)}
							{privilegs.indexOf("rosterAdmin") && (
								<li>
									<a href={process.env.REACT_APP_ROSTER} rel="noopener noreferrer">
										Dienstplan Administration
									</a>
									<NavLink to="dienstplan"></NavLink>
								</li>
							)}
							{privilegs.indexOf("showFaq") > -1 ? (
								<li>
									<a href={process.env.REACT_APP_FAQ} rel="noopener noreferrer" target="_blank">
										FAQ Corona-Virus ➔
									</a>
								</li>
							) : null}
							{privilegs.indexOf("showCoronaAerzte") > -1 ? (
								<li>
									<a href={process.env.REACT_APP_PRAXEN} rel="noopener noreferrer" target="_blank">
										Bereitschaft SARS-CoV-2 Diagnostik ➔
									</a>
								</li>
							) : null}
							<li onClick={onLogout}>
								<span>{username} abmelden</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default Menu;
