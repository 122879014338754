import React from "react";

const Tabelle = ({ data }) => {
	const content =
		data && data.length
			? data.map((entry, index) => {
					return (
						<tr key={index}>
							<td>{entry.date}</td>
							<td>{entry.countEntries}</td>
							<td className="tableGreen">{entry.countCoronaBeratung}</td>
							<td className="tableYellow">{entry.countVaccinationBeratung}</td>
							<td className="tableRed">{entry.countWeitergabe}</td>
							<td className="tableBlue">{entry.countDirect}</td>
							<td className="tableRed">{entry.CoronaAbklaerung}</td>
							<td className="tableRed">{entry.Arztpraxis}</td>
							<td className="tableRed">{entry.Kinderarzt}</td>
							<td className="tableRed">{entry.Psychiater}</td>
							<td className="tableRed">{entry.Hausbesuch}</td>
							<td className="tableRed">{entry.Gynaekologe}</td>
							<td className="tableRed">{entry.Hals}</td>
							<td className="tableRed">{entry.Augenarzt}</td>
							<td className="tableRed">{entry.Zahnarzt}</td>
							<td className="tableRed">{entry.Rettungswagen}</td>
							<td className="tableRed">{entry.Spital}</td>
							<td className="tableRed">{entry.Apotheke}</td>
							<td className="tableRed">{entry.Sonstiges}</td>
							<td>{entry.tazContacted}</td>
						</tr>
					);
			  })
			: null;

	return (
		<table>
			<thead>
				<tr>
					<th className="right">Datum</th>
					<th>
						<span>Total Patienten-Erfassung</span>
					</th>
					<th className="tableGreen">
						<span>Total Corona Beratung</span>
					</th>
					<th className="tableYellow">
						<span>Total Impfberatung</span>
					</th>
					<th className="tableRed">
						<span>Total Weitergabe</span>
					</th>
					<th className="tableBlue">
						<span>Total direkt weitergeleitet</span>
					</th>
					<th className="tableRed">
						<span>Corona-Abklärungen</span>
					</th>
					<th className="tableRed">
						<span>Arztpraxis (NFA)</span>
					</th>
					<th className="tableRed">
						<span>Kinderarzt</span>
					</th>
					<th className="tableRed">
						<span>Psychiater</span>
					</th>
					<th className="tableRed">
						<span>Hausbesuch (MAV)</span>
					</th>
					<th className="tableRed">
						<span>Gynäkologe</span>
					</th>
					<th className="tableRed">
						<span>Hals, Nasen, Ohren</span>
					</th>
					<th className="tableRed">
						<span>Augenarzt</span>
					</th>
					<th className="tableRed">
						<span>Zahnarzt</span>
					</th>
					<th className="tableRed">
						<span>Rettungswagen</span>
					</th>
					<th className="tableRed">
						<span>Spital</span>
					</th>
					<th className="tableRed">
						<span>Apotheke</span>
					</th>
					<th className="tableRed">
						<span>Sonstiges</span>
					</th>
					<th>
						<span>TAZ kontaktiert</span>
					</th>
				</tr>
			</thead>
			<tbody>{content}</tbody>
		</table>
	);
};

export default Tabelle;
