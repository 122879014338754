import * as actionTypes from "../actions/actionTypes";

const initialState = {
	token: null,
	refreshToken: null,
	expTime: null,
	sessionInterval: null,
	loading: false,
	dataLoaded: false,
	data: null,
	error: null,
	success: null,
	autoSignupTried: false,
	realname: "",
	version: "1.1",
	privilegs: [],
	userId: null,
	areas: [],
	monthYears: [],
	selectedMonthYear: null,
	selectedAreas: [],
	chooseAreas: false
};

const authLogout = (state, action) => {
	clearInterval(state.sessionInterval);
	return { ...initialState, autoSignupTried: true };
};

const updateTokens = (state, action) => {
	return { ...state, token: action.token, refreshToken: action.refreshToken, expTime: action.expTime, autoSignupTried: true };
};

const sessionTimeOut = (state, action) => {
	return { ...state, sessionTimeOut: true };
};

const startLoading = (state, action) => {
	return { ...state, loading: true, error: null };
};

const stopLoading = (state, action) => {
	return { ...state, loading: false };
};

const setError = (state, action) => {
	return { ...state, error: action.error };
};

const clearError = (state, action) => {
	return { ...state, error: null };
};

const setSuccess = (state, action) => {
	return { ...state, success: action.success };
};

const clearSuccess = (state, action) => {
	return { ...state, success: null };
};

const setSessionInterval = (state, action) => {
	return { ...state, sessionInterval: action.sessionInterval };
};

const setAutoLoginTried = (state, action) => {
	return { ...state, autoSignupTried: true };
};

const setAreas = (state, action) => {
	return { ...state, areas: action.areas };
};

const setData = (state, action) => {
	return { ...state, data: action.data };
};

const setInitialData = (state, action) => {
	return { ...state, realname: action.realname, privilegs: action.privilegs, userId: action.userId };
};

const setMonthYears = (state, action) => {
	return { ...state, monthYears: action.monthYears };
};

const clearData = (state, action) => {
	return { ...state, data: null };
};

const updateSelectedAreas = (state, action) => {
	return { ...state, selectedAreas: [...action.selectedAreas] };
};

const updateSelectedMonthYear = (state, action) => {
	return { ...state, selectedMonthYear: action.selectedMonthYear };
};

const chooseAreas = (state, action) => {
	return { ...state, chooseAreas: true };
};

const cancelChooseAreas = (state, action) => {
	return { ...state, chooseAreas: false };
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.START_LOADING:
			return startLoading(state, action);
		case actionTypes.STOP_LOADING:
			return stopLoading(state, action);
		case actionTypes.AUTH_LOGOUT:
			return authLogout(state, action);
		case actionTypes.SET_ERROR:
			return setError(state, action);
		case actionTypes.CLEAR_ERROR:
			return clearError(state, action);
		case actionTypes.SET_SUCCESS:
			return setSuccess(state, action);
		case actionTypes.CLEAR_SUCCESS:
			return clearSuccess(state, action);
		case actionTypes.AUTH_UPDATE_TOKENS:
			return updateTokens(state, action);
		case actionTypes.AUTH_SESSION_TIMEOUT:
			return sessionTimeOut(state, action);
		case actionTypes.AUTH_SET_SESSIONINTERVAL:
			return setSessionInterval(state, action);
		case actionTypes.AUTO_LOGIN_TRIED:
			return setAutoLoginTried(state, action);
		case actionTypes.SET_INITIAL_DATA:
			return setInitialData(state, action);
		case actionTypes.SET_DATA:
			return setData(state, action);
		case actionTypes.SET_AREAS:
			return setAreas(state, action);
		case actionTypes.SET_MONTH_YEARS:
			return setMonthYears(state, action);
		case actionTypes.CLEAR_DATA:
			return clearData(state, action);
		case actionTypes.UPDATE_SELECTED_AREAS:
			return updateSelectedAreas(state, action);
		case actionTypes.UPDATE_SELECTED_MONTHYEAR:
			return updateSelectedMonthYear(state, action);

		case actionTypes.CHOOSE_AREAS:
			return chooseAreas(state, action);
		case actionTypes.CANCEL_CHOOSE_AREAS:
			return cancelChooseAreas(state, action);

		default:
			return state;
	}
};

export default reducer;
