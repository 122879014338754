import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import AreaChooser from "../components/Areas/AreaChooser/AreaChooser";

import { _cancelChooseAreas } from "../store/actions/actions";

const AreaChooserHoc = ({ children }) => {
	const chooseAreas = useSelector((state) => state.chooseAreas);
	const dispatch = useDispatch();
	const cancelChooseAreasNow = () => dispatch(_cancelChooseAreas());

	return (
		<Fragment>
			{chooseAreas && <AreaChooser cancel={cancelChooseAreasNow} />}
			{children}
		</Fragment>
	);
};

export default AreaChooserHoc;
