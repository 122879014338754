import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { _stundenImTag, _clearData } from "../../store/actions/actions";

import AreaChooserHoc from "../../hoc/AreaChooserHoc";
import StatisticChooser from "../../components/StatisticChooser/StatisticChooser";
import Areas from "../../components/Areas/Areas";
import MonthYear from "../../components/MonthYear/MonthYear";
import Diagramm from "../../components/Diagramm/StundenImTag/Diagramm";

const StundenImTag = () => {
	const selectedAreas = useSelector((state) => state.selectedAreas);
	const dispatch = useDispatch();
	const stundenImTag = useCallback((areaIds) => dispatch(_stundenImTag(areaIds)), [dispatch]);
	const clearData = useCallback(() => dispatch(_clearData()), [dispatch]);

	useEffect(() => {
		stundenImTag(selectedAreas);
	}, [stundenImTag, selectedAreas]);

	useEffect(() => {
		return () => {
			clearData();
		};
	}, [clearData]);

	return (
		<AreaChooserHoc>
			<div id="fixPanel">
				<StatisticChooser />
				<div className="detailSelector">
					<Areas />
					<MonthYear />
				</div>
				<Diagramm />
			</div>
		</AreaChooserHoc>
	);
};

export default StundenImTag;
