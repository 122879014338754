import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { _clearData } from "../../store/actions/actions";

import StatisticChooser from "../../components/StatisticChooser/StatisticChooser";
import AreaChooser from "../../components/IndividuelleAuswertung/AreaChooser";

const IndividuelleAuswertung = () => {
	const [monthYear, setMonthYear] = useState(null);
	const monthYears = useSelector((state) => state.monthYears);
	const dispatch = useDispatch();
	const clearData = useCallback(() => dispatch(_clearData()), [dispatch]);

	useEffect(() => {
		if (monthYears.length && !monthYear) {
			setMonthYear(monthYears[0].monthYear);
		}
	}, [monthYear, monthYears]);

	useEffect(() => {
		return () => {
			clearData();
		};
	}, [clearData]);

	return (
		<div id="panel">
			<StatisticChooser />
			<AreaChooser />
		</div>
	);
};

export default IndividuelleAuswertung;
