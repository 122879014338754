import React from 'react';

import AerztefonLogoImg from '../assets/images/aerztefon_logo_claim.png';


const AerztefonLogo = ({ version }) => {
	return (
		<div id="aerztefonLogo">
			<img src={AerztefonLogoImg} alt="Aerztefon"/>
			<span>V{version}</span>
		</div>
	)
}

export default AerztefonLogo;