import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { _sarsCov2Vaccinations, _clearData } from "../../store/actions/actions";

import StatisticChooser from "../../components/StatisticChooser/StatisticChooser";
import Tabelle from "../../components/Tabelle/SarsCov2Vaccinations/Tabelle";

const SarsCov2Vaccinations = () => {
	const data = useSelector((state) => state.data);
	const dispatch = useDispatch();
	const sarsCov2Vaccinations = useCallback(() => dispatch(_sarsCov2Vaccinations()), [dispatch]);
	const clearData = useCallback(() => dispatch(_clearData()), [dispatch]);

	useEffect(() => {
		sarsCov2Vaccinations();
	}, [sarsCov2Vaccinations]);

	useEffect(() => {
		return () => {
			clearData();
		};
	}, [clearData]);

	return (
		<div id="panel">
			<StatisticChooser />
			<Tabelle data={data} />
		</div>
	);
};

export default SarsCov2Vaccinations;
