import React, { useEffect, useCallback, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import { _getAreas, _chooseAreas } from "../../store/actions/actions";

const Areas = () => {
	const areas = useSelector((state) => state.areas);
	const selectedAreas = useSelector((state) => state.selectedAreas);
	const dispatch = useDispatch();
	const getAreas = useCallback(() => dispatch(_getAreas()), [dispatch]);
	const chooseAreas = () => dispatch(_chooseAreas());

	useEffect(() => {
		if (!areas.length) {
			getAreas();
		}
	}, [areas, getAreas]);

	if (areas.length) {
		const selectedAreaNames = areas.filter((entry) => selectedAreas.indexOf(entry.id) > -1).map((entry) => entry.area);
		return (
			<Fragment>
				<div className="areas">
					<strong>
						{selectedAreaNames.length ? selectedAreaNames.length + " " : ""}Bereich{selectedAreaNames.length > 1 && "e"}:
					</strong>{" "}
					{selectedAreaNames.length ? (
						selectedAreaNames.map((entry, index) => <span key={index}>{entry}</span>)
					) : (
						<span>
							<i>Alle Bereiche</i>
						</span>
					)}{" "}
				</div>
				<div className="changeArea">
					<button type="button" onClick={chooseAreas}>
						Ändern
					</button>
				</div>
			</Fragment>
		);
	} else {
		return null;
	}
};

export default Areas;
