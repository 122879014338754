import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { _einsatzProWochentag, _clearData } from "../../store/actions/actions";

import AreaChooserHoc from "../../hoc/AreaChooserHoc";
import StatisticChooser from "../../components/StatisticChooser/StatisticChooser";
import Areas from "../../components/Areas/Areas";
import MonthYear from "../../components/MonthYear/MonthYear";
import Diagramm from "../../components/Diagramm/ProWochentag/Diagramm";

const ProWochentag = () => {
	const selectedAreas = useSelector((state) => state.selectedAreas);
	const dispatch = useDispatch();
	const einsatzProWochentag = useCallback((areaIds) => dispatch(_einsatzProWochentag(areaIds)), [dispatch]);
	const clearData = useCallback(() => dispatch(_clearData()), [dispatch]);

	useEffect(() => {
		einsatzProWochentag(selectedAreas);
	}, [einsatzProWochentag, selectedAreas]);

	useEffect(() => {
		return () => {
			clearData();
		};
	}, [clearData]);

	return (
		<AreaChooserHoc>
			<div id="fixPanel">
				<StatisticChooser />
				<div className="detailSelector">
					<Areas />
					<MonthYear />
				</div>
				<Diagramm />
			</div>
		</AreaChooserHoc>
	);
};

export default ProWochentag;
