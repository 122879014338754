import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { _getMonthYears, _updateSelectedMonthYear } from "../../store/actions/actions";

import { ReactComponent as ArrowLeft } from "../../assets/images/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrowRight.svg";

const MonthYear = () => {
	const dispatch = useDispatch();
	const getMonthYears = useCallback(() => dispatch(_getMonthYears()), [dispatch]);
	const updateSelectedMonthYear = useCallback((selectedMonthYear) => dispatch(_updateSelectedMonthYear(selectedMonthYear)), [dispatch]);
	const monthYears = useSelector((state) => state.monthYears);
	const selectedMonthYear = useSelector((state) => state.selectedMonthYear);
	const monthNames = ["", "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

	let decreaseMonthDisabled = false;
	let increaseMonthDisabled = false;

	useEffect(() => {
		if (monthYears.length && !selectedMonthYear) {
			updateSelectedMonthYear(monthYears[0].monthYear);
		}
	}, [selectedMonthYear, monthYears, updateSelectedMonthYear]);

	useEffect(() => {
		if (!monthYears.length) {
			getMonthYears();
		}
	}, [getMonthYears, monthYears]);

	const increaseMonth = () => {
		const monthYearKeys = monthYears.map((entry) => entry.monthYear);
		updateSelectedMonthYear(monthYears[monthYearKeys.indexOf(selectedMonthYear) - 1].monthYear);
	};

	const decreaseMonth = () => {
		const monthYearKeys = monthYears.map((entry) => entry.monthYear);
		updateSelectedMonthYear(monthYears[monthYearKeys.indexOf(selectedMonthYear) + 1].monthYear);
	};

	if (monthYears) {
		const datesMenu = monthYears.map((entry, index) => {
			if (selectedMonthYear === entry.monthYear) {
				if (index === 0) {
					increaseMonthDisabled = true;
				} else if (index === monthYears.length - 1) {
					decreaseMonthDisabled = true;
				}
			}
			const [month, year] = entry.monthYear.split("-");
			return (
				<option key={entry.monthYear} value={entry.monthYear}>
					{monthNames[month]} {year}
				</option>
			);
		});
		return (
			<div className="monthNavigator">
				<div onClick={decreaseMonthDisabled ? () => {} : decreaseMonth} className={"monthNav" + (decreaseMonthDisabled ? " disabled" : "")}>
					<ArrowLeft className="red" />
				</div>
				<div className="select">
					<select onChange={(event) => updateSelectedMonthYear(event.target.value)} value={selectedMonthYear ? selectedMonthYear : ""} size="1">
						{datesMenu}
					</select>
				</div>
				<div onClick={increaseMonthDisabled ? () => {} : increaseMonth} className={"monthNav" + (increaseMonthDisabled ? " disabled" : "")}>
					<ArrowRight className="red" />
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default MonthYear;
