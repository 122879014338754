import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { _updateSelectedAreas } from "../../../store/actions/actions";

const AreaChooser = ({ cancel }) => {
	const areas = useSelector((state) => state.areas);
	const selectedAreas = useSelector((state) => state.selectedAreas);
	const [filter, setFilter] = useState("");
	const [choosenAreas, setChoosenArea] = useState([...selectedAreas]);
	const dispatch = useDispatch();
	const updateSelectedAreas = (selectedAreas) => dispatch(_updateSelectedAreas(selectedAreas));

	const contains = (row) => {
		let searchStrings = [filter];
		if (filter.indexOf(" ") > -1) {
			searchStrings = filter.split(" ");
		}

		const found = searchStrings.map((search) => {
			search = search.toLowerCase();
			if (row.toLowerCase().indexOf(search) > -1) {
				return 1;
			} else {
				return 0;
			}
		});
		const foundCount = found.reduce((a, b) => a + b, 0);
		return foundCount === searchStrings.length;
	};

	const addArea = (id) => {
		setChoosenArea((prev) => [...prev, id]);
	};

	const removeArea = (id) => {
		setChoosenArea((prev) => prev.filter((entry) => entry !== id));
	};

	const updateAreas = () => {
		updateSelectedAreas(choosenAreas);
		cancel();
	};

	return (
		<div id="areaChooser">
			<div>
				<div className="header">Bereiche wählen</div>
				<div className="chooser">
					<div>
						<input type="text" className="search" placeholder="Bereich suchen..." value={filter} onChange={(event) => setFilter(event.target.value)} />
						<ul className="leftList">
							{areas
								.filter((entry) => choosenAreas.indexOf(entry.id) === -1 && (filter.length < 3 || contains(entry.area)))
								.map((entry) => (
									<li key={entry.id} onClick={() => addArea(entry.id)}>
										{entry.area}
									</li>
								))}
						</ul>
					</div>
					<div>
						Gewählte Bereiche
						<ul className="rightList">
							{choosenAreas.length ? (
								choosenAreas.map((entry) => {
									const name = areas.find((area) => area.id === entry).area;
									return (
										<li key={entry} onClick={() => removeArea(entry)}>
											{name}
										</li>
									);
								})
							) : (
								<li key={0} className="noAction">
									<i>Alle Bereiche</i>
								</li>
							)}
						</ul>
					</div>
				</div>
				<div className="buttonRow">
					<button type="button" onClick={cancel}>
						Abbrechen
					</button>
					<button type="button" className="right" onClick={updateAreas}>
						OK
					</button>
				</div>
			</div>
		</div>
	);
};

export default AreaChooser;
