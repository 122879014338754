import React, { Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { tryAutoSignup } from "./store/actions/actions";

import Layout from "./hoc/Layout/Layout";

import Login from "./containers/Login/Login";

import ProWochentag from "./containers/ProWochentag/ProWochentag";
import ProMonat from "./containers/ProMonat/ProMonat";
import IndividuelleAuswertung from "./containers/IndividuelleAuswertung/IndividuelleAuswertung";
import StundenImTag from "./containers/StundenImTag/StundenImTag";
import AuswertungCorona from "./containers/AuswertungCorona/AuswertungCorona";
import SarsCov2Vaccinations from "./containers/SarsCov2Vaccinations/SarsCov2Vaccinations";

const App = () => {
	const authenticated = useSelector((state) => state.token !== null);
	const autoSignupTried = useSelector((state) => state.autoSignupTried);
	const privilegs = useSelector((state) => state.privilegs);

	const dispatch = useDispatch();
	const onTryAutoSignup = () => dispatch(tryAutoSignup());

	let route = null;

	if (!autoSignupTried) {
		onTryAutoSignup();
	} else {
		if (authenticated) {
			route = (
				<Switch>
					<Route path="/proWochentag" component={ProWochentag} />
					<Route path="/proMonat" component={ProMonat} />
					<Route path="/stundenImTag" component={StundenImTag} />
					{privilegs.indexOf("els_statistic_all") > -1 && <Route path="/auswertungCorona" component={AuswertungCorona} />}
					{privilegs.indexOf("els_statistic_all") > -1 && <Route path="/sarsCoV2Vaccinations" component={SarsCov2Vaccinations} />}
					{privilegs.indexOf("els_statistic_all") > -1 && <Route path="/individuelleAuswertung" component={IndividuelleAuswertung} />}
					<Redirect to="/proWochentag" />
				</Switch>
			);
		} else {
			route = (
				<Switch>
					<Route path="/" exact component={Login} />
					<Redirect to="/" />
				</Switch>
			);
		}
	}

	return (
		<Layout>
			<Suspense fallback={<p>Loading...</p>}>{route}</Suspense>
		</Layout>
	);
};

export default App;
