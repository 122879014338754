import React from 'react';

import ScreenMediaLogoImg from '../assets/images/ScreenMediaLogo.png';


const ScreenMediaLogo = () => {
	return (
		<a href="https://www.screenmedia.ch" target="_blank" rel="noopener noreferrer"><img id="screenMediaLogo" src={ScreenMediaLogoImg} alt="ScreenMedia GmbH"/></a>
	)
}

export default ScreenMediaLogo;