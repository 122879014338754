import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { _auswertungCorona, _clearData } from "../../store/actions/actions";

import StatisticChooser from "../../components/StatisticChooser/StatisticChooser";
import Tabelle from "../../components/Tabelle/AuswertungCorona/Tabelle";

const AuswertungCorona = () => {
	const data = useSelector((state) => state.data);
	const dispatch = useDispatch();
	const auswertungCorona = useCallback(() => dispatch(_auswertungCorona()), [dispatch]);
	const clearData = useCallback(() => dispatch(_clearData()), [dispatch]);

	useEffect(() => {
		auswertungCorona();
	}, [auswertungCorona]);

	useEffect(() => {
		return () => {
			clearData();
		};
	}, [clearData]);

	return (
		<div id="panel">
			<StatisticChooser />
			<Tabelle data={data} />
		</div>
	);
};

export default AuswertungCorona;
