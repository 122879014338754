import * as actionTypes from "./actionTypes";

import axios from "../../aerzteAxios";

export const startLoading = () => {
	return {
		type: actionTypes.START_LOADING
	};
};

export const stopLoading = () => {
	return {
		type: actionTypes.STOP_LOADING
	};
};

export const _logout = () => {
	localStorage.removeItem("token");
	localStorage.removeItem("expTime");
	localStorage.removeItem("refreshToken");
	return {
		type: actionTypes.AUTH_LOGOUT
	};
};

export const setError = (error) => {
	return {
		type: actionTypes.SET_ERROR,
		error: error
	};
};

export const clearError = () => {
	return {
		type: actionTypes.CLEAR_ERROR
	};
};

export const clearSuccess = () => {
	return {
		type: actionTypes.CLEAR_SUCCESS
	};
};

const getExpTime = (expiresIn) => {
	return new Date().getTime() + expiresIn;
};

const sessionTimeOut = () => {
	return {
		type: actionTypes.AUTH_SESSION_TIMEOUT
	};
};

export const startSessionChecker = (dispatch) => {
	return window.setInterval(() => {
		console.log("Check session timeout");
		const expTime = localStorage.getItem("expTime");
		if (new Date().getTime() > expTime) {
			dispatch(sessionTimeOut());
		}
	}, 60000);
};

export const setSessionInterval = (sessionInterval) => {
	return {
		type: actionTypes.AUTH_SET_SESSIONINTERVAL,
		sessionInterval: sessionInterval
	};
};

const setInitialData = (data) => {
	return {
		type: actionTypes.SET_INITIAL_DATA,
		realname: data.realname,
		privilegs: data.privilegs,
		userId: data.userId
	};
};

const setAreas = (areas) => {
	return {
		type: actionTypes.SET_AREAS,
		areas: areas
	};
};

export const _getAreas = () => {
	return (dispatch) => {
		axios
			.get("areas")
			.then((response) => {
				if (response.config.updatedToken) {
					const { token, refreshToken, expiresIn } = response.config.updatedToken;
					const expTime = getExpTime(expiresIn);
					dispatch(updateTokens(token, refreshToken, expTime));
				}
				dispatch(setAreas(response.data));
			})
			.catch((err) => {
				dispatch(setError(err.response.data.message));
			});
	};
};

export const _login = (eMail, password) => {
	return (dispatch) => {
		dispatch(startLoading());
		const authData = {
			eMail: eMail,
			password: password
		};

		axios
			.post("login", authData)
			.then((response) => {
				console.log(response.data);
				const expTime = getExpTime(response.data.expiresIn);
				const sessionInterval = startSessionChecker(dispatch);
				dispatch(setSessionInterval(sessionInterval));
				dispatch(updateTokens(response.data.token, response.data.refreshToken, expTime));
				dispatch(setInitialData(response.data));
				dispatch(stopLoading());
			})
			.catch((err) => {
				dispatch(setError(err.response.data.message));
				dispatch(stopLoading());
			});
	};
};

const updateTokens = (token, refreshToken, expTime) => {
	localStorage.setItem("token", token);
	localStorage.setItem("expTime", expTime);
	localStorage.setItem("refreshToken", refreshToken);
	return {
		type: actionTypes.AUTH_UPDATE_TOKENS,
		token: token,
		refreshToken: refreshToken,
		expTime: expTime
	};
};

export const tryAutoSignup = () => {
	return (dispatch) => {
		const token = localStorage.getItem("token");
		const refreshToken = localStorage.getItem("refreshToken");
		if (!token) {
			dispatch(_logout());
		} else {
			const expTime = localStorage.getItem("expTime");
			if (new Date().getTime() > expTime) {
				dispatch(_logout());
			} else {
				axios
					.get("autoSignup")
					.then((response) => {
						dispatch(setInitialData(response.data));
						if (response.config.updatedToken) {
							const { token, refreshToken, expiresIn } = response.config.updatedToken;
							const expTime = getExpTime(expiresIn);
							dispatch(updateTokens(token, refreshToken, expTime));
						} else {
							dispatch(updateTokens(token, refreshToken, expTime));
						}
						const sessionInterval = startSessionChecker(dispatch);
						dispatch(setSessionInterval(sessionInterval));
					})
					.catch((err) => {
						if (err.response.status === 401 || err.response.status === 403) {
							dispatch(_logout());
						}
					});
			}
		}
	};
};

const setData = (data) => {
	return {
		type: actionTypes.SET_DATA,
		data: data
	};
};

const setMonthYears = (monthYears) => {
	return {
		type: actionTypes.SET_MONTH_YEARS,
		monthYears: monthYears
	};
};

export const _getMonthYears = () => {
	return (dispatch) => {
		axios
			.get("monthYears")
			.then((response) => {
				console.log(response.data);
				dispatch(setMonthYears(response.data));
				if (response.config.updatedToken) {
					const { token, refreshToken, expiresIn } = response.config.updatedToken;
					const expTime = getExpTime(expiresIn);
					dispatch(updateTokens(token, refreshToken, expTime));
				}
			})
			.catch((err) => {
				if (err.response.status === 401 || err.response.status === 403) {
					dispatch(_logout());
				}
			});
	};
};

export const _einsatzProWochentag = (areaIds) => {
	return (dispatch) => {
		dispatch(startLoading());
		const ids = areaIds.length ? areaIds.map((id) => "areaIds[]=" + id).join("&") : "";
		axios
			.get("einsatzProWochentag?" + ids)
			.then((response) => {
				console.log(response.data);
				dispatch(setData(response.data));
				if (response.config.updatedToken) {
					const { token, refreshToken, expiresIn } = response.config.updatedToken;
					const expTime = getExpTime(expiresIn);
					dispatch(updateTokens(token, refreshToken, expTime));
				}
				dispatch(stopLoading());
			})
			.catch((err) => {
				if (err.response.status === 401 || err.response.status === 403) {
					dispatch(_logout());
				}
				dispatch(stopLoading());
			});
	};
};

export const _einsatzProMonat = (areaIds) => {
	return (dispatch) => {
		dispatch(startLoading());
		const ids = areaIds.length ? areaIds.map((id) => "areaIds[]=" + id).join("&") : "";
		axios
			.get("einsatzProMonat?" + ids)
			.then((response) => {
				console.log(response.data);
				dispatch(setData(response.data));
				if (response.config.updatedToken) {
					const { token, refreshToken, expiresIn } = response.config.updatedToken;
					const expTime = getExpTime(expiresIn);
					dispatch(updateTokens(token, refreshToken, expTime));
				}
				dispatch(stopLoading());
			})
			.catch((err) => {
				if (err.response.status === 401 || err.response.status === 403) {
					dispatch(_logout());
				}
				dispatch(stopLoading());
			});
	};
};

export const _stundenImTag = (areaIds) => {
	return (dispatch) => {
		dispatch(startLoading());
		const ids = areaIds.length ? areaIds.map((id) => "areaIds[]=" + id).join("&") : "";
		axios
			.get("stundenImTag?" + ids)
			.then((response) => {
				console.log(response.data);
				dispatch(setData(response.data));
				if (response.config.updatedToken) {
					const { token, refreshToken, expiresIn } = response.config.updatedToken;
					const expTime = getExpTime(expiresIn);
					dispatch(updateTokens(token, refreshToken, expTime));
				}
				dispatch(stopLoading());
			})
			.catch((err) => {
				if (err.response.status === 401 || err.response.status === 403) {
					dispatch(_logout());
				}
				dispatch(stopLoading());
			});
	};
};

export const _clearData = () => {
	return {
		type: actionTypes.CLEAR_DATA
	};
};

export const _auswertungCorona = () => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.get("auswertungCorona")
			.then((response) => {
				//console.log(response.data);
				dispatch(setData(response.data));
				if (response.config.updatedToken) {
					const { token, refreshToken, expiresIn } = response.config.updatedToken;
					const expTime = getExpTime(expiresIn);
					dispatch(updateTokens(token, refreshToken, expTime));
				}
				dispatch(stopLoading());
			})
			.catch((err) => {
				if (err.response.status === 401 || err.response.status === 403) {
					dispatch(_logout());
				}
				dispatch(stopLoading());
			});
	};
};

export const _sarsCov2Vaccinations = () => {
	return (dispatch) => {
		dispatch(startLoading());
		axios
			.get("sarsCov2Vaccinations")
			.then((response) => {
				//console.log(response.data);
				dispatch(setData(response.data));
				if (response.config.updatedToken) {
					const { token, refreshToken, expiresIn } = response.config.updatedToken;
					const expTime = getExpTime(expiresIn);
					dispatch(updateTokens(token, refreshToken, expTime));
				}
				dispatch(stopLoading());
			})
			.catch((err) => {
				if (err.response.status === 401 || err.response.status === 403) {
					dispatch(_logout());
				}
				dispatch(stopLoading());
			});
	};
};

export const _updateSelectedAreas = (selectedAreas) => {
	return {
		type: actionTypes.UPDATE_SELECTED_AREAS,
		selectedAreas: selectedAreas
	};
};

export const _updateSelectedMonthYear = (selectedMonthYear) => {
	return {
		type: actionTypes.UPDATE_SELECTED_MONTHYEAR,
		selectedMonthYear: selectedMonthYear
	};
};

export const _chooseAreas = () => {
	return {
		type: actionTypes.CHOOSE_AREAS
	};
};

export const _cancelChooseAreas = () => {
	return {
		type: actionTypes.CANCEL_CHOOSE_AREAS
	};
};
