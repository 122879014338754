import React from "react";
import { useSelector } from "react-redux";

const Diagramm = () => {
	const selectedMonthYear = useSelector((state) => state.selectedMonthYear);
	const data = useSelector((state) => state.data);
	const selectedData = data && data.monthYear ? data.monthYear.find((entry) => entry.monthYear === selectedMonthYear) : null;

	let countOperations = 0;
	const content =
		selectedData && selectedData.hours
			? selectedData.hours.map((entry, index) => {
					countOperations += entry.countOperations;
					return (
						<div className="hour" style={{ height: (100 / data.maxValue) * entry.countOperations + "%" }} key={index}>
							<span>{entry.countOperations}</span>
							<div>{entry.label}</div>
						</div>
					);
			  })
			: null;

	return (
		<div id="stundenImTag" className="diagramm">
			<div className="countOperations">Total Einsätze: {countOperations.toLocaleString("de-CH")}</div>
			{content}
		</div>
	);
};

export default Diagramm;
