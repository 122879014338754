import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { _login } from "../../store/actions/actions";

import Error from "../../ui/Error";

const Login = () => {
	const [eMail, setEMail] = useState("");
	const [password, setPassword] = useState("");
	const dispatch = useDispatch();

	const sendLogin = (eMail, password) => dispatch(_login(eMail, password));

	const changeEMail = (event) => {
		setEMail(event.target.value);
	};

	const changePasswort = (event) => {
		setPassword(event.target.value);
	};

	const login = (event) => {
		event.preventDefault();
		sendLogin(eMail, password);
	};

	return (
		<React.Fragment>
			<h2>Statistik</h2>
			<form className="login" onSubmit={login}>
				<Error />
				<div className="content">
					<h2>Login</h2>
					<div>
						<label>E-Mail</label>
						<input type="text" value={eMail} onChange={changeEMail} />
					</div>
					<div>
						<label>Passwort</label>
						<input type="password" value={password} onChange={changePasswort} />
					</div>
					<p>
						<button type="submit">Anmelden</button>
					</p>
				</div>
			</form>
		</React.Fragment>
	);
};

export default Login;
