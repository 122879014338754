export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_DATA = "SET_DATA";
export const CLEAR_DATA = "CLEAR_DATA";
export const SET_MONTH_YEARS = "SET_MONTH_YEARS";
export const SET_AREAS = "SET_AREAS";
export const SET_INITIAL_DATA = "SET_INITIAL_DATA";
export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const AUTH_UPDATE_TOKENS = "AUTH_UPDATE_TOKENS";
export const AUTH_SESSION_TIMEOUT = "AUTH_SESSION_TIMEOUT";
export const AUTH_SET_SESSIONINTERVAL = "AUTH_SET_SESSIONINTERVAL";
export const SET_REDIRECT = "SET_REDIRECT";
export const AUTO_LOGIN_TRIED = "AUTO_LOGIN_TRIED";
export const SET_REAL_NAME = "SET_REAL_NAME";

export const UPDATE_SELECTED_AREAS = "UPDATE_SELECTED_AREAS";
export const UPDATE_SELECTED_MONTHYEAR = "UPDATE_SELECTED_MONTHYEAR";
export const CHOOSE_AREAS = "CHOOSE_AREAS";
export const CANCEL_CHOOSE_AREAS = "CANCEL_CHOOSE_AREAS";
