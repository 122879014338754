import React from 'react';
import { useSelector } from 'react-redux';

const Error = () => {
	const error = useSelector(state => state.error);
	let result = '';
	if (error) {
		if (typeof error === 'object') {
			result = error.map((entry, index) => <div key={index}>{ entry }</div>);
		} else {
			result = error;
		}
	}
	
	return result && <div className="error">{ result }</div>;
}

export default Error;